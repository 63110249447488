///
/// !!! Cookies !!!
/// The Enact Learning Cookie is the cookie we have the ID in to track user progress
/// The Enact Cookie is set by the ENACT client so we can tie the enact ID to the learning center
///

import { GetCookieDomain } from "../../utils/env"

const ENACT_COOKIE_NAME = "AccessTokenDetails",
      ENACT_LEARNING_COOKIE_NAME = "EnactLearningUser"

interface EnactCookie {
  nbf?: number,
  exp?: number,
  iss?: string,
  aud?: Array<string>,
  client_id?: string,
  client_primaryClient?: boolean,
  sub?: string,
  auth_time?: number,
  idp?: string,
  name?: string,
  tenant?: string,
  role?: string,
  clientType?: string,
  username?: string,
  UserId?: string,
  TenantDisplayName?: string,
  IsSSOUser?: string,
  HashUrl?: string,
  jtic?: string,
  Email?: string,
  userLicense?: string,
  primaryClient?: string,
  jti?: string,
  sid?: string,
  iat?: number,
  scope?: Array<string>
  amr?: Array<string>
}
interface EnactLearningCookie {
  uid: string,
  enact_tenant_display_name: string,
  enact_user_id: string,
}

function ReadEnactLearningCookie():EnactLearningCookie|undefined {
  try {
    let cookieStr = ReadCookie(ENACT_LEARNING_COOKIE_NAME)
    if(!cookieStr) {
      return undefined
    }

    let dataStr = atob(cookieStr)

    let data:EnactLearningCookie = JSON.parse(dataStr)

    return data
  } catch (err) {
    console.error(err)
    return undefined
  }
}

function ReadEnactCookie():EnactCookie|undefined {
  try {
    let cookieVal = ReadCookie(ENACT_COOKIE_NAME)
    if(!cookieVal) {
      console.warn("Enact Cookie Not Found")
      return undefined
    }
    
    let params = new URLSearchParams(cookieVal).get("AccessToken") || ""
    
    let token = params.split('.')
  
    let raw = atob(token[1])
  
    let data:EnactCookie = JSON.parse(raw)
  
    return data
  } catch (err) {
    console.error(err)
    return undefined
  }
}

function UpdateEnactLearningCookie() {
  let enactCookie = ReadEnactCookie()
  if(enactCookie) {
    WriteEnactLearningCookie(enactCookie)
  }
}

function WriteEnactLearningCookie(value: EnactCookie|undefined) {
  if(!value) {
    WriteCookie(ENACT_LEARNING_COOKIE_NAME, "", -1)
  } else {
    // Stringify and encode the data
    let newCookie:EnactLearningCookie = {
      uid: `${value.TenantDisplayName}_${value.UserId}`,
      enact_tenant_display_name: value.TenantDisplayName || "",
      enact_user_id: value.UserId || ""
    }

    let str = JSON.stringify(newCookie)
    let raw = btoa(str)

    // Write the cookie
    WriteCookie(ENACT_LEARNING_COOKIE_NAME, raw, 90)
  }
}

function WriteCookie(name:string, value:string, expirationDays:number) {
  const d = new Date()
  d.setTime(d.getTime() + (expirationDays*24*60*60*1000))
  const cookieDomain = GetCookieDomain()
  const encodedValue = encodeURIComponent(value)
  document.cookie = `${name}=${encodedValue}; expires=${d.toUTCString()}; path=/;${cookieDomain ? ` domain=${cookieDomain};` : ''}`
}

function ReadCookie(name:string):string {
  let cname = `${name}=`
  let decodedCookie = decodeURIComponent(document.cookie)
  let cookieItems = decodedCookie.split(';')
  // console.log('items: ', cookieItems)
  for(let i = 0; i < cookieItems.length; i++) {
    let c = cookieItems[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    // console.log(c)
    if(c.indexOf(cname) == 0) {
      return c.substring(cname.length, c.length)
    }
  }

  return ""
}

export {
  ReadEnactLearningCookie,
  UpdateEnactLearningCookie
}