import React, { useEffect } from "react"

import 'bootstrap/dist/css/bootstrap.min.css'
import "../../assets/stylesheets/sitestyles.css"

import { UpdateEnactLearningCookie } from "../../siteUtils/cookies"

interface GlobalLayoutProps {
  children: any
}
function GlobalLayout({ children }:GlobalLayoutProps):JSX.Element {

  useEffect(()=>{
    UpdateEnactLearningCookie()
  },[])
  return (
    <>
      <main>
        {children}
      </main>
    </>
  )
}

export default GlobalLayout