
function GetStoryblokToken(ENV, BRIDGE) {
  if(BRIDGE) {
    return process.env.GATSBY_BRIDGE_STORYBLOK_ACCESS_TOKEN
  }
  if((ENV && ENV.NETLIFY) || process.env.NETLIFY) {
    if((ENV && ENV.CONTEXT === "production") || process.env.CONTEXT === "production") {
      return ENV ? ENV.GATSBY_PROD_STORYBLOK_ACCESS_TOKEN : process.env.GATSBY_PROD_STORYBLOK_ACCESS_TOKEN
    } else if ((ENV && ENV.CONTEXT === "branch-deploy") || process.env.CONTEXT === "branch-deploy") {
      // TODO:: IF multiple branches add branch name check here `ENV.BRANCH`
      return ENV ? ENV.GATSBY_PREVIEW_STORYBLOK_ACCESS_TOKEN : process.env.GATSBY_PREVIEW_STORYBLOK_ACCESS_TOKEN
    }
  }
  return ENV ? ENV.GATSBY_DEV_STORYBLOK_ACCESS_TOKEN : process.env.GATSBY_DEV_STORYBLOK_ACCESS_TOKEN
}

function GetStoryblokVersion(ENV) {
  if((ENV && ENV.NETLIFY) || process.env.NETLIFY) {
    if((ENV && ENV.CONTEXT === "production") || process.env.CONTEXT === "production") {
      return "published"
    }
  }
  return "draft"
}

function GetFaunaToken(ENV) {
  if((ENV && ENV.NETLIFY) || process.env.NETLIFY) {
    if((ENV && ENV.CONTEXT === "production") || process.env.CONTEXT === "production") {
      return ENV ? ENV.PROD_FAUNA_AUTH_TOKEN : process.env.PROD_FAUNA_AUTH_TOKEN
    } else if ((ENV && ENV.CONTEXT === "branch-deploy") || process.env.CONTEXT === "branch-deploy") {
      // TODO:: IF multiple branches add branch name check here `ENV.BRANCH`
      return ENV ? ENV.PREVIEW_FAUNA_AUTH_TOKEN : process.env.PREVIEW_FAUNA_AUTH_TOKEN
    }
  }
  return ENV ? ENV.DEV_FAUNA_AUTH_TOKEN : process.env.DEV_FAUNA_AUTH_TOKEN
}

function GetFaunaEndpoint(ENV) {
  if((ENV && ENV.NETLIFY) || process.env.NETLIFY) {
    if((ENV && ENV.CONTEXT === "production") || process.env.CONTEXT === "production") {
      return ENV ? ENV.PROD_FAUNA_GRAPHQL_ENDPOINT : process.env.PROD_FAUNA_GRAPHQL_ENDPOINT
    } else if ((ENV && ENV.CONTEXT === "branch-deploy") || process.env.CONTEXT === "branch-deploy") {
      // TODO:: IF multiple branches add branch name check here `ENV.BRANCH`
      return ENV ? ENV.PREVIEW_FAUNA_GRAPHQL_ENDPOINT : process.env.PREVIEW_FAUNA_GRAPHQL_ENDPOINT
    }
  }
  return ENV ? ENV.DEV_FAUNA_GRAPHQL_ENDPOINT : process.env.DEV_FAUNA_GRAPHQL_ENDPOINT
}

function GetStoryblokBuildWebhook(ENV) {
  if((ENV && ENV.NETLIFY) || process.env.NETLIFY) {
    if((ENV && ENV.CONTEXT === "production") || process.env.CONTEXT === "production") {
      return ENV ? ENV.PROD_STORYBLOK_BUILD_WEBHOOK : process.env.PROD_STORYBLOK_BUILD_WEBHOOK
    } else if((ENV && ENV.CONTEXT === "branch-deploy") || process.env.CONTEXT === "branch-deploy") {
      return ENV ? ENV.PREVIEW_STORYBLOK_BUILD_WEBHOOK : process.env.PREVIEW_STORYBLOK_BUILD_WEBHOOK
    }
  }
  return ENV ? ENV.DEV_STORYBLOK_BUILD_WEBHOOK : process.env.DEV_STORYBLOK_BUILD_WEBHOOK
}

function GetRebuildSchedulerFileUrl(ENV) {
  if((ENV && ENV.NETLIFY) || process.env.NETLIFY) {
    if((ENV && ENV.CONTEXT === "production") || process.env.CONTEXT === "production") {
      return ENV ? ENV.PROD_NETLIFY_FUNCTION_REBUILD_SCHEDULER_FILE_URL : process.env.PROD_NETLIFY_FUNCTION_REBUILD_SCHEDULER_FILE_URL
    } else if((ENV && ENV.CONTEXT === "branch-deploy") || process.env.CONTEXT === "branch-deploy") {
      return ENV ? ENV.PREVIEW_NETLIFY_FUNCTION_REBUILD_SCHEDULER_FILE_URL : process.env.PREVIEW_NETLIFY_FUNCTION_REBUILD_SCHEDULER_FILE_URL
    }
  }
  return ENV ? ENV.DEV_NETLIFY_FUNCTION_REBUILD_SCHEDULER_FILE_URL : process.env.DEV_NETLIFY_FUNCTION_REBUILD_SCHEDULER_FILE_URL
}

function GetCookieDomain(ENV) {
  if(ENV && ENV.GATSBY_COOKIE_DOMAIN) {
    return ENV.GATSBY_COOKIE_DOMAIN
  }
  if(window && window.location && window.location.host === "preview--enact-learning.netlify.app") {
    return process.env.GATSBY_PREVIEW_COOKIE_DOMAIN
  }
  return process.env.GATSBY_COOKIE_DOMAIN
}

function GetGoogleTagManagerId() {
  if(process.env.NETLIFY && process.env.CONTEXT === "production") {
    return process.env.PROD_GOOGLE_TAG_MANAGER_ID;
  }
  // Cannot be empty
  return "1"
}

module.exports = {
  GetFaunaToken,
  GetFaunaEndpoint,
  GetStoryblokToken,
  GetStoryblokVersion,
  GetStoryblokBuildWebhook,
  GetRebuildSchedulerFileUrl,
  GetCookieDomain,
  GetGoogleTagManagerId
}
